$(function(){
	var startPos = 0,winScrollTop = 0;
	$(window).on('scroll',function(){
		winScrollTop = $(this).scrollTop();
		if (winScrollTop >= startPos) {
			if(winScrollTop >= 200){
				$('.pagetop').fadeIn(100).css('display','flex');
			}
		} else {
			$('.pagetop').fadeOut(200);
		}
		startPos = winScrollTop;
	});
});

// gnav

//humberger menu

$(function(){
	var _touch = ('ontouchstart' in document) ? 'touchstart' : 'click';
	$(".menu").on(_touch,function() {
		$(".menu").toggleClass("active");
		$(".gnav").slideToggle();

		//$('.gnav__wrapper').toggleClass("on");
		$(".nav").fadeToggle(200);
		return false;
	});

	// $(document).on(_touch,function() {
	// 	$(".menu").removeClass("active");
	// 	$(".gnav").fadeOut(200);
	// });
	// $(".menu").on(_touch,function() {
	// 	event.stopPropagation();
	// });
});

//dropdown menu

$(function(){
	var $dropdown = $('.gnav__link--submenu');
	var DURATION = 200; //アニメーションの速さ
	function fadeOutMenu(){
	  $dropdown.removeClass('active')
		.next('.gnav__sublist')
		.stop()
		.slideUp(DURATION);
	}
	//表示を切り替える
	function toggleMenu(){
	  var $self = $(this); //thisにはクリックした時の要素が入る
	  //要素が.is-activeを持っていない場合
	  if(!$self.hasClass('active')){
		fadeOutMenu();
	  }
	  //クリックした要素を表示させる
	  $self.toggleClass('active')
		.next('.gnav__sublist')
		.stop().slideToggle(DURATION);
	}
	$dropdown.on('click', toggleMenu);

	

  //別の場所をクリックすると閉じる処理
  	$(document).on('click touchend', function(event) {
		if (!$(event.target).closest('.gnav').length) {
		// ここに処理;
		fadeOutMenu();//関数呼びだし
	}
  });
});

$(function(){ 
	if(window.matchMedia('(max-width:768px)').matches) {
		$('.gnav__link--sub').on('click', function() {
			$(".menu").toggleClass("active");
			$(".gnav").slideToggle();
			$(this).parents('.gnav__sublist').slideUp();
		});
	}
});
//scroll

$(function () {
    $(".gnav__link--sub,.utlnav__link").on("click", function(){
        var headerHeight = $("header").outerHeight();
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? "body" : href);
        var position = target.offset().top - headerHeight - 20;
        $("html, body").animate({ scrollTop: position }, 600, "swing");
    });
});

//scroll
$(function(){
	//$('a[href^="#"]').click(function(){
	$('.pagetop').on("click", function(){
		var speed = 500;
		var href= $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top;
		$("html, body").animate({scrollTop:position}, speed, "swing");
		return false;
	});
});

//modal

$(function(){
    $('.modal-open').each(function(){
        $(this).on('click',function(){
            var target = $(this).data('target');
            var modal = document.getElementById(target);
            $(modal).fadeIn();
            return false;
        });
    });
    $('.modal__close,.modal__bg').on('click',function(){
        $('.modal').fadeOut();
        return false;
    }); 
});

//slider

// $(document).ready(function(){
// 	$('#slide').slick({
// 		infinite: true,
// 		autoplay: true,
// 		// slidesToShow: 1,
// 		// slidesToScroll: 1,
// 		//centerMode: true,
// 		arrows: true,
// 		dots: false,
// 		fade: true,
// 		pauseOnFocus: false,
// 		pauseOnHover: false,
// 		speed: 1000,
// 	});
// 	$('#gallery').slick({
// 		infinite: true,
// 		autoplay: true,
// 		slidesToShow: 4,
// 		slidesToScroll: 1,
// 		//centerMode: true,
// 		//slidesToShow: 1,
// 		//slidesToScroll: 1,
// 		centerMode: true,
// 		variableWidth: true,
// 		arrows: true,
// 		dots: true,
// 		fade: false,
// 		pauseOnFocus: false,
// 		pauseOnHover: false,
// 		speed: 1000,
// 	});
// 	$('#gallery__slide').slick({
// 		autoplay: false,
// 		dots: false,
// 		infinite: false,
// 		speed: 300,	
// 		slidesToShow: 1,
// 		centerMode: true,
// 		variableWidth: true
// 	});

	// $('#gallery').slick({
	// 	infinite: true,
	// 	autoplay: true,
	// 	slidesToShow: 4,
	// 	slidesToScroll: 1,
	// 	centerMode: true,
	// 	arrows: false,
	// 	dots: false,
	// 	responsive: [
	// 		{
	// 		  breakpoint: 1024,
	// 		  settings: {
	// 			slidesToShow: 3,
	// 			slidesToScroll: 1,
	// 		  }
	// 		},
	// 		{
	// 		  breakpoint: 600,
	// 		  settings: {
	// 			slidesToShow: 2,
	// 			slidesToScroll: 2
	// 		  }
	// 		},
	// 		{
	// 		  breakpoint: 480,
	// 		  settings: {
	// 			slidesToShow: 1,
	// 			slidesToScroll: 1,
	// 		  }
	// 		}
	// 	  ]
	// });
//});




//item



//animation

  $(function(){
	//$('.anime').css("opacity","0");
	$(window).scroll(function (){
	  $(".anime").each(function(){
		var imgPos = $(this).offset().top;    
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
		  $(this).addClass('move');
		} 
		// else {
		//   $(this).removeClass('move');
		//   // $(this).css({ 
		//   //   "font-size": "20px",
		//   //   "padding": "20px"
		//   // });
		// }
	  });
	  //Right
	  $(".anime-r").each(function(){
		var imgPos = $(this).offset().top;    
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
		  $(this).addClass('move-r');
		}
	  });
	  //left
	  $(".anime-l").each(function(){
		var imgPos = $(this).offset().top;    
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
		  $(this).addClass('move-l');
		}
	  });
  
	  //dilay
	  $(".anime-d").each(function(){
		var imgPos = $(this).offset().top;    
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
		  $(this).addClass('move-d');
		}
	  });
	  //bottom
	  $(".anime-b").each(function(){
		var imgPos = $(this).offset().top;    
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
		  $(this).addClass('move-b');
		}
	  });
  
	});
  });